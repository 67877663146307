import { useRef } from 'react';
import { Box } from '@mui/material';
import { Empty } from '../empty';
import { KnockoutBrackets, BracketsRounds, Download } from './components';
import { calculateMaxRound } from './utils';
import { KNOCKOUT_STANDINGS_BRACKETS } from './__tests__/test-ids';
import type { KnockoutBracket, Locale, Round } from './types';

export type BracketsProps = {
  locale?: Locale;
  data: KnockoutBracket[];
  rounds?: Round[];
  withDownload?: boolean;
  withEmptyIcon?: boolean;
};

export const Brackets = ({
  locale,
  data,
  rounds,
  withDownload,
  withEmptyIcon,
}: BracketsProps) => {
  const bracketsRef = useRef<HTMLDivElement>();

  const hasItems = data?.length > 0;
  const maxRound = calculateMaxRound(data);

  if (!hasItems) {
    return (
      <Empty
        withIcon={withEmptyIcon}
        text={locale?.emptyData ?? 'No bracket data available'}
      />
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        {withDownload && (
          <Download
            maxRound={maxRound}
            bracketsRef={bracketsRef}
            locale={locale}
          />
        )}
      </Box>
      <Box mt={5}>
        <Box sx={{ overflow: 'auto' }} data-qa={KNOCKOUT_STANDINGS_BRACKETS}>
          <div ref={bracketsRef}>
            {rounds && <BracketsRounds rounds={rounds} data={data} />}
            <KnockoutBrackets data={data} locale={locale} />
          </div>
        </Box>
      </Box>
    </Box>
  );
};
