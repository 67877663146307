import { api } from '@core/services';
import type { KnockoutBracket } from '@ui-components';

interface KnockoutStandingsBracketsResponse {
  header: Array<any>;
  _embedded: {
    items: KnockoutBracket[];
  };
  _links: {
    self: {
      href: string;
    };
    createGroup?: {
      href: string;
    };
  };
}

export const groupsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBrackets: build.query<KnockoutBracket[], number>({
      query: (groupId) => `groups/${groupId}/brackets`,
      providesTags: ['Brackets'],
      transformResponse: (response: KnockoutStandingsBracketsResponse) =>
        response._embedded.items,
    }),
  }),
});

export const { useGetBracketsQuery } = groupsApi;
