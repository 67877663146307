import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Box,
  Radio,
  Button,
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
} from '@mui/material';
import { prop } from 'ramda';
import {
  PaginationLimits,
  Competition as CompetitionType,
  User,
  UserRoleType as UserRoleTypeValues,
  AssignmentsUsersOriginType,
} from '@core/types';
import { CustomTable, PersonNameColumn } from '@core/components';
import {
  areUsersLoading,
  getCompetitionContactsUsers,
} from '@core/store/modules/pages/assignments/selectors';
import { actions as assignmentsActions } from '@core/store/modules/pages/assignments';
import {
  getRegionalCompetitionManagerId,
  getUserRoleTypeValues,
} from '@core/helpers';
import {
  Column,
  MTableBodyRow,
  type MaterialTableProps,
} from '@core/components/material-table';
import {
  ASSIGN_MANAGERS_MODAL,
  ASSIGN_MANAGERS_MODAL_ASSIGN_BUTTON,
  ASSIGN_MANAGERS_MODAL_CANCEL_BUTTON,
  ASSIGN_MANAGERS_MODAL_USERS,
} from './__tests__/test-ids';
import { AssignmentsUsersOrigin } from '@core/constants';
import { useCoreDispatch } from '@core/hooks';

type Props = {
  isModalOpen: boolean;
  competition: CompetitionType;
  assignedPersons: Array<User>;
  closeModal: () => void;
  reloadCallback: () => void;
};

const limit: PaginationLimits = 5;
const usersOrigin: AssignmentsUsersOriginType = AssignmentsUsersOrigin.CONTACTS;

const AssignManager = ({
  isModalOpen,
  competition,
  assignedPersons,
  closeModal,
  reloadCallback,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useCoreDispatch();

  const [selected, setSelected] = useState<number>();
  const [query, setQuery] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const isLoading = useSelector(areUsersLoading);
  const users = useSelector(getCompetitionContactsUsers);
  const userRoleValues = getUserRoleTypeValues();
  const regionalCompetitionManagers = users._embedded?.users;

  const handleSearch = (params: { query: string }) => {
    setQuery(params.query);
    setPage(1);
  };

  const isDisabled = (personId: number) =>
    assignedPersons.some(
      (user) => getRegionalCompetitionManagerId(user) === personId,
    );

  const toggleSelected: MaterialTableProps<User>['onRowClick'] = (
    _event,
    person,
  ) => {
    const regionalCompetitionManagerId =
      getRegionalCompetitionManagerId(person);

    if (!isDisabled(regionalCompetitionManagerId)) {
      setSelected(regionalCompetitionManagerId);
    }
  };

  const handleClose = () => {
    setQuery('');
    closeModal();
  };

  const onReload = ({ page: newPage }: { page: number }) => setPage(newPage);

  const columns: Column<User>[] = [
    {
      field: 'actions',
      cellStyle: {
        width: 10,
        padding: '8px 0 8px 8px',
      },
      render: (user) => {
        const regionalCompetitionManagerId =
          getRegionalCompetitionManagerId(user);

        return (
          <Box display="flex" alignItems="center">
            <Radio
              checked={
                +selected === +regionalCompetitionManagerId ||
                isDisabled(regionalCompetitionManagerId)
              }
              disabled={isDisabled(regionalCompetitionManagerId)}
              value={regionalCompetitionManagerId}
              inputProps={{ 'aria-label': 'Select user' }}
            />
          </Box>
        );
      },
    },
    {
      title: t('Name'),
      render: ({ firstName, lastName, profilePictureFileLink }) => (
        <PersonNameColumn
          person={{
            localFirstName: firstName,
            localFamilyName: lastName,
            logoFileLink: profilePictureFileLink,
          }}
          showInitials
          withLogo
        />
      ),
    },
    { title: t('Email Address'), field: 'email' },
    {
      title: t('User Roles'),
      render: ({ userRoles }) =>
        userRoles.map(({ role }) => userRoleValues[role]).join(', '),
    },
  ];

  const handleAssign = () => {
    closeModal();
    dispatch(
      assignmentsActions.assignRegionalCompetitionManager({
        reloadCallback,
        personId: selected,
        competitionId: competition?.id,
      }),
    );
  };

  const getPaginationValues = () => ({
    page,
    pages: prop('pages', users),
    limit: prop('limit', users),
    total: prop('total', users),
    reloadData: onReload,
  });

  useEffect(() => {
    dispatch(
      assignmentsActions.getUsers({
        queryParams: new URLSearchParams([
          ['query', query],
          ['page', `${page}`],
          ['limit', `${limit}`],
          [
            'filter[role]',
            `${UserRoleTypeValues.ROLE_REGIONAL_COMPETITION_MANAGER}`,
          ],
        ]),
        origin: usersOrigin,
      }),
    );
  }, [query, page, dispatch]);

  return (
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      maxWidth="md"
      fullWidth
      data-qa={ASSIGN_MANAGERS_MODAL}
    >
      <DialogTitle>{t('Assign manager')}</DialogTitle>
      <DialogContent>
        <Box mb={3} display="flex">
          <div>
            {t(
              'Select a regional competition manager you want to grant access to',
            )}{' '}
            <span style={{ fontWeight: 'bold' }}>{competition?.title}</span>
          </div>
        </Box>
        <Box mb={3}>
          <Card>
            <Box overflow="hidden auto">
              <CustomTable
                columns={columns}
                isLoading={isLoading}
                data={regionalCompetitionManagers || []}
                options={{
                  toolbar: false,
                  actionsColumnIndex: 0,
                }}
                tableSearch={{ query, onSearch: handleSearch }}
                rowActions={{
                  handleRowClick: toggleSelected,
                }}
                disableBodyElevation
                pagination={getPaginationValues()}
                components={{
                  Row: (props) => (
                    <MTableBodyRow
                      data-qa={ASSIGN_MANAGERS_MODAL_USERS}
                      {...props}
                    />
                  ),
                }}
              />
            </Box>
          </Card>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button
              variant="outlined"
              onClick={handleClose}
              data-qa={ASSIGN_MANAGERS_MODAL_CANCEL_BUTTON}
            >
              {t('Cancel')}
            </Button>
          </Box>
          <Button
            variant="contained"
            onClick={handleAssign}
            disabled={!selected}
            data-qa={ASSIGN_MANAGERS_MODAL_ASSIGN_BUTTON}
          >
            {t('Assign')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AssignManager;
