import type { TreeNode } from './types';

const findNodeAndParent = (
  nodes: TreeNode[],
  id: TreeNode['id'],
  parent: TreeNode['id'] | null = null,
): { node?: TreeNode; parent?: TreeNode['id'] } => {
  for (const node of nodes) {
    if (node.id === id) {
      return { node, parent };
    }

    if (node.children) {
      const result = findNodeAndParent(node.children, id, node.id);

      if (result.node) {
        return result;
      }
    }
  }

  return {};
};

const collectChildren = (
  node: TreeNode,
): Pick<TreeNode, 'id' | 'disabled' | 'children'>[] => {
  let children: Pick<TreeNode, 'id' | 'disabled' | 'children'>[] = [];

  if (node.children) {
    for (const child of node.children) {
      children.push({
        id: child.id,
        disabled: child.disabled || node.disabled,
        children: child.children,
      });
      children = children.concat(collectChildren(child));
    }
  }

  return children;
};

export const getNodeRelations = (
  id: TreeNode['id'],
  nodes: TreeNode[],
): {
  parent: TreeNode['id'] | undefined;
  children: Pick<TreeNode, 'id' | 'disabled' | 'children'>[];
  node?: TreeNode;
} => {
  const { node, parent } = findNodeAndParent(nodes, id);
  const children = node ? collectChildren(node) : [];

  return { parent, children, node };
};

export const getAllParentIds = (
  id: TreeNode['id'],
  nodes: TreeNode[],
): TreeNode['id'][] => {
  const { parent } = getNodeRelations(id, nodes);

  if (!parent) {
    return [];
  }

  return [...getAllParentIds(parent, nodes), parent];
};

export const hasDisabledChildren = (node: Partial<TreeNode>): boolean => {
  if (node.disabled) {
    return true;
  }

  if (node.children) {
    return node.children.some(
      (child) => child.disabled || hasDisabledChildren(child),
    );
  }

  return false;
};

export const isEveryChildSelected = (
  node: Partial<TreeNode>,
  selectedItems: TreeNode['id'][],
): boolean => {
  if (node.children?.length && selectedItems.length) {
    return node.children
      .filter((child) => !hasDisabledChildren(child))
      .every(
        (child) =>
          selectedItems.includes(child.id) ||
          isEveryChildSelected(child, selectedItems),
      );
  }

  return false;
};
