import { Grid, Typography } from '@mui/material';
import Icon from './icon';

export interface EmptyProps {
  text: string;
  withIcon?: boolean;
}

export const Empty = ({ text, withIcon }: EmptyProps) => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    flexWrap="wrap"
    flexDirection="column"
  >
    {withIcon && <Icon />}
    <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
      {text}
    </Typography>
  </Grid>
);
