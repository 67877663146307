import type { KnockoutBracket } from './types';

/* logic to which bracket to populate in which grid space */
export const getTemplateAreas = (rounds: number) => {
  let result = '';
  const resultArray: Array<string> = [];
  const matrix: Array<Array<string>> = [];
  const rows = Math.pow(2, rounds);
  const columns = rounds + 2;
  let boxIdCounter = 0;
  let maxConsecutiveBoxes = 2;

  for (let column = 0; column < columns; column++) {
    if (column !== 1) {
      let startingDots =
        column < 2
          ? 0
          : column === 2
            ? 1
            : Math.pow(2, Math.floor(column - 2)) - 1;
      const startingDotsCopy = startingDots;
      matrix[column] = [];
      let dotCounter = 0;
      let boxCounter = 0;
      let flag = false;
      let flag2 = 0;
      if (column === 3) {
        maxConsecutiveBoxes += 4;
      } else if (column === 4) {
        maxConsecutiveBoxes = 2;
      }
      for (let row = 0; row < rows; row++) {
        if (startingDots > 0 && dotCounter < startingDots && boxCounter === 0) {
          matrix[column].push('.');
          dotCounter++;
        } else {
          if (column > 2 && matrix[column - 1][row] !== '.') {
            matrix[column].push(matrix[column - 1][row]);
          } else {
            matrix[column].push('Box' + boxIdCounter);
          }
          boxCounter++;
          dotCounter = 0;
          if (!flag) {
            startingDots = column < 4 ? startingDots * 2 : startingDots - 1;
            flag = true;
          }
          if (
            column >= 4 &&
            (row === 3 * (startingDotsCopy + 1) ||
              (row > 3 * (startingDotsCopy + 1) &&
                row === flag2 + Math.pow(2, column - 1) &&
                startingDots === startingDotsCopy - 1))
          ) {
            startingDots = startingDotsCopy * 2;
            flag2 = row;
          }

          if (
            column >= 4 &&
            row > 3 * (startingDotsCopy + 1) &&
            row === flag2 + Math.pow(2, column - 1) &&
            startingDots === startingDotsCopy * 2
          ) {
            startingDots = startingDotsCopy - 1;
            flag2 = row;
          }
          if (boxCounter === maxConsecutiveBoxes) {
            if (
              column < 4 ||
              (column >= 4 && matrix[column - 1][row] === '.')
            ) {
              boxIdCounter++;
            }
            boxCounter = 0;
          }
        }
        if (row === rows - 1 && column > 1) {
          matrix[column][row] = '.';
        }
      }
    } else {
      matrix[column] = matrix[column - 1].slice(0);
    }
  }

  for (let row = 0; row < rows; row++) {
    resultArray.push('"');
    for (let column = 0; column < columns; column++) {
      resultArray[resultArray.length - 1] +=
        matrix[column][row] + (column < columns - 1 ? ' ' : '"');
    }
  }
  result = resultArray.join('\n');

  return result;
};

/* depending on how many rounds we have, there will be different number of columns for more visibility on the screen and user-friendly */
export const getTemplateColumns = (round: number) => {
  const columns = round > 2 ? round + 2 : round * 2;

  return `repeat(${columns}, minmax(100px, 1fr))`;
};

export const calculateMaxRound = (items: KnockoutBracket[]) =>
  items?.length ? Math.log2(items.length + 1) : 1;
