import { useTranslation } from 'react-i18next';
import { Brackets } from '@ui-components';
import { FullScreenSpinner } from '@core/components';
import { useGetBracketsQuery } from '@core/services/groups';
import type { CompetitionResponse, Group } from '@core/types';

type Props = {
  stage: Group;
  competition: CompetitionResponse;
};

const BracketsTab = ({ stage, competition }: Props) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetBracketsQuery(stage.id);

  const rounds = stage.childGroups.map((group) => ({
    id: group.id,
    title: group.title,
  }));

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  return (
    <Brackets
      withDownload
      withEmptyIcon
      rounds={rounds}
      data={data}
      locale={{
        filename: `${competition.title}_${stage.title}`,
        downloadText: t('Download'),
        emptyData: t('No bracket data available'),
      }}
    />
  );
};

export default BracketsTab;
