import { SvgIcon } from '@mui/material';

const DefaultTeamLogo = () => (
  <SvgIcon
    viewBox="0 0 20 20"
    sx={{
      borderRadius: '50%',
      border: '1px solid rgb(189, 189, 189)',
      backgroundColor: 'rgb(238, 238, 238)',
    }}
  >
    <path d="M10,0.8L2.5,4.2v5c0,4.6,3.2,8.9,7.5,10c4.3-1,7.5-5.4,7.5-10v-5L10,0.8 M10,2.7l5.8,2.6v4.1c0,1.4-0.4,2.9-1.1,4.1c-1.4-1-3.7-1.4-4.7-1.4s-3.3,0.4-4.7,1.4c-0.7-1.3-1.1-2.7-1.1-4.1V5.3L10,2.7 M10,5C8.4,5,7.1,6.3,7.1,7.9s1.3,2.9,2.9,2.9s2.9-1.3,2.9-2.9S11.6,5,10,5 M10,6.7c0.7,0,1.2,0.6,1.2,1.2c0,0.6-0.5,1.3-1.2,1.3S8.8,8.6,8.8,7.9S9.3,6.7,10,6.7M10,13.8c1.3,0,3,0.5,3.8,1.1c-1,1.3-2.4,2.3-3.8,2.6c-1.4-0.4-2.7-1.4-3.8-2.6C7,14.3,8.7,13.8,10,13.8z" />
  </SvgIcon>
);

export default DefaultTeamLogo;
