import clsx from 'clsx';
import { Box } from '@mui/material';
import DefaultTeamLogo from './default-team-logo';
import MatchResults from './match-results';
import { useBracketStyle } from './brackets-style';
import {
  BRACKET_AGGREGATED_SCORE,
  BRACKET_SCORE,
  BRACKET_TITLE,
} from '../__tests__/test-ids';
import type { MatchData, TeamType } from '../types';

interface TeamProps {
  team: TeamType;
  teamName: string;
  matches: MatchData[];
  isWinnerAway: boolean;
  isWinnerHome: boolean;
}

const Team = ({
  team,
  teamName,
  matches,
  isWinnerAway,
  isWinnerHome,
}: TeamProps) => {
  const classes = useBracketStyle();

  return (
    <div
      className={clsx(classes.row1, {
        [classes.row1Winner]: isWinnerHome,
      })}
    >
      <div className={classes.nameAndLogoHome}>
        <div className={classes.logoWrapper}>
          {team.logoUrl ? (
            <Box
              component="img"
              src={team.logoUrl}
              alt={`${teamName} logo`}
              crossOrigin="anonymous"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                textAlign: 'center',
              }}
            />
          ) : (
            <DefaultTeamLogo />
          )}
        </div>
        <div
          className={clsx(classes.teamNameLabelContainer, {
            [classes.teamNameLabelContainerIsWinner]: isWinnerHome,
            [classes.teamNameLabelContainerIsNotWinner]: isWinnerAway,
          })}
          title={teamName}
          data-qa={BRACKET_TITLE}
        >
          <span className={classes.teamNameLabel}>{teamName}</span>
        </div>
      </div>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <Box
          className={clsx({
            [classes.scoreWrapperIsNotWinner]: isWinnerAway,
          })}
          sx={{
            width: 100,
          }}
        >
          <Box
            sx={{
              height: '100%',
              alignItems: 'center',
            }}
            className={classes.scoreWrapper}
            data-qa={BRACKET_SCORE}
          >
            <MatchResults teamId={team.id} matches={matches} />
          </Box>
        </Box>

        <div
          className={clsx(classes.aggregateScore, {
            [classes.aggregateScoreIsNotWinner]: isWinnerAway,
            [classes.aggregateScoreHidden]: team.aggregateScore === null,
          })}
          data-qa={BRACKET_AGGREGATED_SCORE}
        >
          {team.aggregateScore}
        </div>
      </Box>
    </div>
  );
};

export default Team;
