import { Box } from '@mui/material';
import Bracket from './bracket';
import BracketLine from './bracket-line';
import {
  calculateMaxRound,
  getTemplateAreas,
  getTemplateColumns,
} from '../utils';
import { useBracketStyle } from './brackets-style';
import { KNOCKOUT_BRACKET } from '../__tests__/test-ids';
import type { KnockoutBracket, Locale } from '../types';

export interface KnockoutBracketsProps {
  data: KnockoutBracket[];
  locale?: Locale;
}

const KnockoutBrackets = ({ data, locale }: KnockoutBracketsProps) => {
  const maxRound = calculateMaxRound(data);
  const classes = useBracketStyle();

  return (
    <Box
      className={classes.gridContainer}
      sx={{
        gridTemplateAreas: getTemplateAreas(maxRound),
        gridTemplateColumns: getTemplateColumns(maxRound),
      }}
    >
      {data.map((bracket, index) => (
        <Box
          key={bracket.id}
          className={classes.gridItem}
          sx={{ gridArea: `Box${index}` }}
          data-qa={KNOCKOUT_BRACKET}
        >
          <Bracket data={bracket} locale={locale} />
          <BracketLine
            data={bracket}
            currentIndex={index}
            isMaxRound={bracket.round === maxRound}
            numberOfItems={data.length}
          />
        </Box>
      ))}
    </Box>
  );
};

export default KnockoutBrackets;
