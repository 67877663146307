import { Box } from '@mui/material';
import { MatchStatus, type MatchData } from '../types';

type Props = {
  matches: MatchData[];
  teamId: number;
};

const getTeamScoreFromMatch = (
  match: MatchData,
  teamId: number,
): number | null => {
  const teamScores = {
    [match.topCompetitorId]: match.topCompetitorScore,
    [match.bottomCompetitorId]: match.bottomCompetitorScore,
  };

  return teamScores[teamId];
};

const MatchResults = ({ matches, teamId }: Props) => {
  const eligableMatches = matches.filter(
    (match) => match.status === MatchStatus.COMPLETE,
  );

  return eligableMatches.map((match, count) => (
    <Box
      key={`${match.id}-${teamId}-${count}`}
      sx={{
        textAlign: 'center',
        fontWeight: 700,
        width: '20%',
      }}
    >
      {getTeamScoreFromMatch(match, teamId)}
    </Box>
  ));
};

export default MatchResults;
