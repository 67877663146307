import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { calculateMaxRound } from '../utils';
import { useBracketStyle } from './brackets-style';
import { KNOCKOUT_BRACKETS_ROUNDS } from '../__tests__/test-ids';
import type { KnockoutBracket, Round } from '../types';

const thirdPlaceMatchTitle = '3rd Place Match';

type Props = {
  rounds: Round[];
  data: KnockoutBracket[];
};

const KnockoutBracketsRounds = ({ rounds, data }: Props) => {
  const classes = useBracketStyle();
  const maxRound = calculateMaxRound(data);

  const bracketRounds = useMemo(
    () =>
      rounds.filter(
        (round) => round.title !== thirdPlaceMatchTitle && !round.extraRound,
      ),
    [rounds],
  );

  const gridArea = useMemo(
    () =>
      bracketRounds.reduce((acc, round, index) => {
        const currentBox = `Box${round.id}`;

        switch (index) {
          case 0:
            return bracketRounds.length > 1
              ? `${acc} ${currentBox} ${currentBox} ${currentBox} ${currentBox} gap`
              : `${acc} ${currentBox} ${currentBox} ${currentBox} end`;
          case bracketRounds.length - 1:
            return `${acc} ${currentBox} ${currentBox} end`;
          default:
            return `${acc} ${currentBox} ${currentBox}`;
        }
      }, '"') + '"',
    [bracketRounds],
  );

  const gridColumns = useMemo(() => {
    const numberOfColumns = maxRound === 1 ? 4 : 2 * (maxRound - 1) + 6;
    return `repeat(${numberOfColumns}, 1fr)`;
  }, [maxRound]);

  return (
    <Box
      className={classes.roundsGridContainer}
      sx={{
        gridTemplateAreas: gridArea,
        gridTemplateColumns: gridColumns,
      }}
    >
      {bracketRounds.map((round) => (
        <Box
          key={round.id}
          className={classes.roundsGridItem}
          sx={{ gridArea: `Box${round.id}` }}
          data-qa={KNOCKOUT_BRACKETS_ROUNDS}
        >
          <Typography variant="h5">{round.title}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default KnockoutBracketsRounds;
