import type { RefObject } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from '@mui/material';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import { DOWNLOAD_BUTTON } from '../__tests__/test-ids';
import type { Locale } from '../types';

type Props = {
  maxRound: number;
  locale?: Locale;
  bracketsRef: RefObject<HTMLDivElement>;
};

const Download = ({ maxRound, locale, bracketsRef }: Props) => {
  const handleDownload = async () => {
    const margin = 10;
    const canvas = await html2canvas(bracketsRef.current, {
      scale: 3,
      useCORS: true,
    });
    const img = canvas.toDataURL('image/jpeg', 2);
    const doc = new jsPDF(maxRound > 5 ? 'p' : 'l', 'mm', 'a4');
    const maxWidth = doc.internal.pageSize.getWidth() - 2 * margin;
    const maxHeight = doc.internal.pageSize.getHeight() - 2 * margin;
    const imgProps = doc.getImageProperties(img);

    let imgWidth = maxWidth;
    let imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    if (imgHeight > maxHeight) {
      imgHeight = maxHeight;
      imgWidth = (imgProps.width * imgHeight) / imgProps.height;
    }

    doc.addImage(img, 'JPEG', margin, margin, imgWidth, imgHeight);

    if (locale?.filename) {
      doc.save(`${locale.filename}.pdf`);
    } else {
      doc.save();
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      size="medium"
      onClick={handleDownload}
      startIcon={<PictureAsPdf />}
      data-qa={DOWNLOAD_BUTTON}
    >
      {locale?.downloadText ?? 'Download'}
    </Button>
  );
};

export default Download;
