export enum MatchStatus {
  BYE = 'BYE',
  SCHEDULED = 'SCHEDULED',
  COMPLETE = 'COMPLETE',
  POSTPONED = 'POSTPONED',
  CANCELLED = 'CANCELLED',
  ABANDONED = 'ABANDONED',
  DRAFT = 'DRAFT',
  FORFEITED = 'FORFEITED',
}

export type Locale = Partial<{
  filename: string;
  downloadText: string;
  emptyData: string;
  emptyTeamName: string;
}>;

export type Round = {
  id: string | number;
  title: string;
  extraRound?: boolean;
};

export interface TeamType {
  id: number | null;
  title: string | null;
  logoUrl: string | null;
  aggregateScore: number | null;
  isWinner: boolean;
}

export interface MatchData {
  id: number;
  topCompetitorId: number | null;
  topCompetitorScore: number | null;
  topCompetitorPenaltyScore?: number | null;
  bottomCompetitorId: number | null;
  bottomCompetitorScore: number | null;
  bottomCompetitorPenaltyScore?: number | null;
  dateTime: string | null;
  venueTitle: string | null;
  status: MatchStatus;
}

export interface KnockoutBracket {
  team1: TeamType;
  team2: TeamType;
  team1Placeholder: string | null;
  team2Placeholder: string | null;
  matches: MatchData[];
  id: number;
  nextBracketId: number | null;
  number: number;
  round: number;
  topIndex: number;
  bottomIndex: number;
  isComplete: boolean;
}
